import { useState, useRef, FormEvent } from "react";
import emailjs from 'emailjs-com';
import FloatingInput from "./FloatingInput";
import { Button, useMatches } from "@mantine/core";
import { IconArrowRight, IconTopologyStar3 } from "@tabler/icons-react";
import { validateForm } from "./Validation";

const Contact = () => {

    const form = {
        Name: "",
        Email: "",
        Phone: "",
        message: ""
    };


    const [formData, setFormData] = useState<{ [key: string]: string }>(form);
    const [formError, setFormError] = useState<{ [key: string]: string }>(form);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const formRef = useRef<HTMLFormElement>(null); // Correctly typing the ref

    const handleChange = (id: string, value: string) => {
        setFormData({ ...formData, [id]: value });
        setFormError({ ...formError, [id]: validateForm(id, value) });
    };

    const sendEmail = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setSuccessMessage("");
        setErrorMessage("");

        const formDataToSend = {
            user_name: formData.Name,
            user_email: formData.Email,
            user_phone: formData.Phone,
            message: formData.message,
        };

        emailjs.send("service_0udxre7", "template_8h3mp8e", formDataToSend, "AuErZzepQNsfQIJsY")
            .then((result) => {
                setLoading(false);
                setSuccessMessage("Message sent successfully!");
                setFormData(form);  // Reset the form after submission
            }, (error) => {
                setLoading(false);
                setErrorMessage("Failed to send message, please try again later.");
            });
    };

    const btn = useMatches({
        xsm: 'xs',
        sm: "sm",
        md: 'md',
        lg: "lg"
    });

    return (
        <div className="px-16 md-mx:px-8 sm-mx:px-4 mx-20 lg-mx:mx-10 md-mx:mx-0 my-10 font-mono" id="Contact">
            <h1 className="text-4xl sm-mx:text-3xl xs-mx:text-2xl mb-10 font-bold text-center text-white">
                <span className="text-primaryColor">05.&nbsp;</span>Contact
            </h1>
            <div data-aos="flip-left" data-aos-duration="800" className="w-[70%] lg-mx:w-full shadow-[0_0_10px_0_#64FFDA50] m-auto flex flex-col gap-6 border border-primaryColor p-8 rounded-3xl sm-mx:p-4">
                <div className="text-3xl flex gap-2 items-center text-white font-semibold sm-mx:text-2xl xs-mx:text-xl">
                    Let's Connect
                    <IconTopologyStar3 className="w-10 text-primaryColor h-10 sm-mx:w-7 sm-mx:h-7" />
                </div>
                <form ref={formRef} onSubmit={sendEmail}>
                    <FloatingInput id="Name" name="Name" value={formData.Name} handleChange={handleChange} error={formError.Name} />
                    <FloatingInput id="Email" name="Email" value={formData.Email} handleChange={handleChange} error={formError.Email} />
                    <FloatingInput id="Phone" name="Phone" value={formData.Phone} handleChange={handleChange} error={formError.Phone} />
                    <FloatingInput id="message" name="message" value={formData.message} handleChange={handleChange} error={formError.message} />
                    <Button fullWidth type="submit" rightSection={<IconArrowRight size={20} />}
                            className="!text-bgColor !font-bold " variant="filled" size={btn} radius="lg" color="#64FFDA"
                            disabled={loading}>
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </form>
                {successMessage && <p className="text-green-500 text-center mt-4">{successMessage}</p>}
                {errorMessage && <p className="text-red-500 text-center mt-4">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default Contact;
