import {IconBrandGithub, IconBrandInstagram, IconBrandLinkedin, IconBrandYoutube} from "@tabler/icons-react";

const Info = {
    name: "Jason Apturahim",
    stack: ["Software Engineer", "Full Stack Developer", "Competitive Programmer", "Freelancer", "Lol Challenger 1018LP Peak"],
    bio: "My journey in the tech world began at a leading German company, where I successfully delivered scalable, secure, and high-performance web applications. With a robust skill set in both front-end and back-end development, I excel at turning complex challenges into seamless digital solutions.\n" +
        "\n" +
        "In addition to my development expertise, I have been a key part of several high-profile live reality productions in Germany, including 'First Dates,' 'The Bachelor,' and 'Bachelor in Paradise.' I provided comprehensive technical and software support throughout these productions, assisting with both low- and high-level system needs. My role included supporting the Media Asset Management (MAM) system and video editing applications, ensuring smooth workflows during live and post-production.\n" +
        ""
}
const ProjectInfo = [
    {
        title: "Ecommerce App",
        desc: "Welcome to my latest project. I am currently developing a fully functional and highly customizable Content Management System (CMS) designed to empower users with the ability to create and manage their own online stores, all without requiring any programming knowledge or technical expertise. This system features an intuitive admin panel that allows users to effortlessly configure and personalize their storefronts to meet their specific needs.\n" +
            "\n" +
            "The CMS is built using the latest technologies, ensuring it is both robust and future-proof. With a fully operational backend, it offers seamless management of products, orders, and customer interactions. Users can easily update content, track performance, and expand their store's functionality as needed, all within a user-friendly environment.\n" +
            "\n" +
            "This project aims to provide a comprehensive solution that not only simplifies the process of setting up an online store but also supports the growth and scalability of the business. Whether you're launching a small boutique or a large-scale e-commerce platform, this CMS is designed to meet your needs, combining ease of use with powerful features that rival those of more complex systems.",
        image: "CmsFrontend.png",
        live: true,
        technologies: ["React", "NodeJS", "MongoDb", "Prisma", "Shadcn UI", "Typescript"],
        link: "https://cms-shop-frontend.vercel.app/",
        github: "https://github.com/crixouss"
    },
    {
        title: "Coin Analyzing Application",
        desc: "CoinGenius is a cutting-edge web application designed for coin collectors, numismatists, and investors who seek accurate, AI-driven coin analysis and market insights. My platform leverages advanced artificial intelligence and machine learning algorithms to evaluate and assess the value of coins based on their condition, rarity, and current market trends.",
        image: "CoinGenius.png",
        live: true,
        technologies: ["React", "NodeJs", "MongoDB", "Express", "Tailwind", "Mongoose"],
        link: "",
        github: "https://github.com/crixouss"
    },
    {
        title: "Website Application (RA Innenausbau)",
        desc: "I designed and developed the RA Innenausbau website, focusing on creating a clean, professional, and responsive design that enhances user experience. The site effectively showcases the company's extensive experience in construction and tiling, with a clear and intuitive layout. I also crafted concise and impactful content to highlight the company’s strengths. Additionally, I implemented basic SEO strategies to improve the site’s visibility on search engines and ensured mobile optimization for seamless functionality across all devices. This project demonstrates my skills in web development, content strategy, and SEO.",
        image: "Website.png",
        live: true,
        technologies: ["NextJS", "React", "Tailwind"],
        link: "https://rainnenausbau.com/",
        github: "https://github.com/crixouss"
    },
]


const SkillInfo = [
    {
        title: "Languages",
        skills: ["German", "English", "Bulgarian",]
    },
    {
        title: "Languages",
        skills: ["JavaScript", "TypeScript", "Java",]
    },
    {
        title: "Backend",
        skills: ["Node JS", "Express JS", "Azure", "MySQL", "MongoDB", "Firebase", "PostgresSQL"]
    },
    {
        title: "Frontend",
        skills: ["React JS", "HTML", "SASS","Tailwind CSS", "JavaScript", "Css", "Bootstrap"
        ]
    },
    {
        title: "Tools",
        skills: ["Git", "Github", "Webstorm", "Postman", "MongoDB Compass","Cisco","Ubuntu", "Kali", "MacOS","Stripe"]
    },
]
const socialLinks = [
    { link: "https://github.com/crixouss", icon: IconBrandGithub },
    { link: "https://www.linkedin.com/in/jason-apturahim-402182224/", icon: IconBrandLinkedin },
    { link: "https://www.instagram.com/jason_apt/", icon: IconBrandInstagram },
    { link: "https://www.youtube.com/@crixousNBarb", icon: IconBrandYoutube },
];


const ExperienceInfo = [
    {
        role: "Junior Full Stack Engineer",
        company: "Systempiloten",
        date: "April 2022 - April 2024",
        desc: "I worked as a Full Stack Engineer on Storymaker. A video editing platform that enables users to perform tasks like raw cut editing, Speech-to-Text transcription and multilingual translation. My role involved utilizing the MERN stack (MongoDB, Express.js, React.js, Node.js) to develop both the front-end and back-end of the application. I was responsible for creating responsive user interfaces using React.js, designing RESTful APIs with Express.js and managing data storage with MongoDB. On the server side, I worked with Node.js to handle asynchronous operations and improve overall performance. Additionally, I collaborated with cross-functional teams to integrate features seamlessly, ensured code quality and optimized performance through regular testing and debugging.",
        skills: ["Javascript", "Typescript", "React JS", "Node JS", "Express", "Azure", "Git",]
    },
    {
        role: "Provided on-premises technical and software support for live productions at Warner Bros.",
        company: "Systempiloten",
        date: "April 2022 - April 2024",
        desc: "I was involved in some of the most prominent live reality productions in Germany, where I provided both low- and high-level technical and software support throughout the productions. Notable projects included 'First Dates,' 'The Bachelor,' and 'Bachelor in Paradise,' among many other events. Our team supported the Media Asset Management (MAM) system and video editing applications used during these productions.",
        skills: ["Software support", "Technical support", "Administration support", "Git",]
    },
    {
        role: "Junior Full Stack Engineer",
        company: "Systempiloten",
        date: "June 2021 -  April 2022",
        desc: "During my apprenticeship at Systempiloten GmbH & CO. KG, I worked extensively with the MERN stack (MongoDB, Express.js, React.js, Node.js) to build scalable and dynamic web applications. My responsibilities included developing responsive front-end interfaces and creating RESTful APIs to manage server-side operations efficiently.",
        skills: ["Javascript", "Typescript", "React JS", "Node JS", "Express", "Azure", "Git",]
    }
]
const Slugs = [
    "systempiloten",
    "german",
    "bulgarian",
    "english",
    "stripe",
    "kali",
    "macOS",
    "ubuntu",
    "cisco",
    "azure",
    "webstorm",
    "typescript",
    "spring",
    "javascript",
    "dart",
    "java",
    "react",
    "angular",
    "flutter",
    "android",
    "html5",
    "css3",
    "springboot",
    "mongodb",
    "selenium",
    "nodedotjs",
    "express",
    "nextdotjs",
    "prisma",
    "mysql",
    "amazonaws",
    "postgresql",
    "firebase",
    "nginx",
    "vercel",
    "testinglibrary",
    "jest",
    "cypress",
    "docker",
    "git",
    "jira",
    "github",
    "gitlab",
    "visualstudiocode",
    "androidstudio",
    "sonarqube",
    "figma",
];
export { Info, ProjectInfo,socialLinks, SkillInfo, ExperienceInfo, Slugs };